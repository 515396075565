@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.subjectPage {
  @include page;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 16px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 16px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-around;

    width: 100%;
    max-width: 400px;
  }

  .subjectHeader {
    margin-left: 16px;
    width: 100%;
  }

  .subjectDescription {
    width: 100%;
    margin-bottom: 16px;

    p {
      white-space: pre-wrap;
    }
  }
}