.tutorClassCard {
  display: flex;
  flex-direction: column;

  .actions {
    padding: 16px 8px;
    margin-top: -8px;

    background-color: #f8f9fa;
    border-radius: 5px;

    .footerActions {
      margin-top: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 8px;
    }
  }

}