.selectedScheduleOptions {
  display: flex;
  flex-direction: column;
  width: 100%;

  .scheduleOption {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 10px;

    .cancelSchedule {
      position: absolute;
      right: 8px;
      top: 8px;
      border-radius: 9999px;
      font-size: 120%;
      font-weight: 900;

      @media screen and (max-width: 658px) {
        font-size: 100%;
      }
    }

    .date {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 658px) {
    width: calc(100% - 32px);
  }
}
