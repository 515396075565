@import '../../styles/layout.scss';

.subjectListPage {
  @include page;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  padding: 16px;

  
  .filters {
    display: flex;
    align-items: center;

    width: 100%;

    border-bottom: 1px solid grey;
    margin-bottom: 16px;

    :global(.ant-form) {
      width: 100%;
      max-width: 500px;
      margin-right: 8px;
    }
  }

  .subjectList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 320px));
    gap: 8px;

    @media screen and (max-width: 500px) {
      grid-template-columns: repeat(auto-fill, minmax(288px, 450px));
    }

    width: 100%;
  }

  .loading {
    position: absolute;
    top: calc(50vh - 24px);
    left: calc(50% - 42px);
  }

}