@import '../../styles/layout.scss';

.tutorListPage {

  @include page;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 100%;

}
