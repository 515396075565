@import '../../styles/layout.scss';

.homePage {
  @include page;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 16px;

  :global(.ant-tabs) {
    width: 100%;

    :global(.ant-tabs-tabpane) {
      padding: 8px 0;
    }
  }

  .classes {
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    .classesSection {
      padding: 8px
    }
  }

  .classList {
    display: grid;
    grid-template-columns: minmax(288px, 360px);
    gap: 8px;

    @media screen and (max-width: 500px) {
      grid-template-columns: minmax(288px, 450px);
    }
  
  }

  .calendar {
    width: 100%;
    height: 75vh;

    @media screen and (max-width: 600px) {
      height: 70vh;
    }
    
    :global(.fc) {
      height: 100%;
    }
  }

}