.paymentForm {
  .card {
    margin: 16px 0;
    padding: 16px;
    border-radius: 5px;
    box-shadow: 0px 2px 6px rgba(60, 64, 67, 0.14),
      0px 1px 2px rgba(60, 64, 67, 0.3);
    z-index: 10;
    background-color: white;
  }
}

.payment_method {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: calc(45vw - 200px);
  margin-top: 12px;
}

.animated {

  animation-name: slides;
  animation-duration: .8s;
  animation-fill-mode: forwards;

  opacity: 0;
  transform: translateY(-75px);

}

@keyframes slides {


  to {
    opacity: 1;
    transform: translateY(0px);
  }

}

.buttons {
  display: flex;
  gap: 12px;
}

@media (max-width: 800px) {
  .payment_method {
    width: calc(100vw - 54px - 200px)
  }
}


@media (max-width: 414px) {
  .payment_method {
    width: calc(100vw - 54px);
  }
}


