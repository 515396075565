.card {

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #fff;

  padding: 14px;
  width: 100%;
  height: 90px;
  gap: 12px;
  color: rgba(0,0,0,.8);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  border-top: 1px solid #d8d8d8;

  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }

  .radio {
    margin-left: 24px;
  }

  .radio_text {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    height: 100%;

    margin-left: 12px;

    span {
      background: #f5f5f5;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-left: 12px;
      margin-right: 22px;
    }

    img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      
    }

    h2 {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1.25;
    }

    p {
      font-size: 0.875rem;
      color: #999;
      font-weight: 400;
      line-height: 1.25;

      margin-top: 4px;
    }

  }

}

@media (max-width: 400px) {
  .card {
  .radio_text {
    p {
      font-size: 0.688rem;
    }
    
    h2 {
      font-size: 0.813rem;
    }
  }
}
}