@import "../../styles/colors.scss";

.logingForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 80%;
  max-width: 600px;
  margin: 0 auto;

  img {
    width: 100px;
  }

  :global(.ant-form-item) {
   width: 100%;
  }

  @media screen and (max-width: 500px) {
    img {
      width: 50px;
    }
  }

  @media screen and (max-height: 800px) and (min-width: 501px){
    img {
      width: 80px;
    }
  }
}

.loginFormContainer{
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 600px;

  padding: 16px;
  margin-bottom: 48px;

  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(60, 64, 67, 0.14),
    0px 1px 2px rgba(60, 64, 67, 0.3);

  h1 {
    font-size: 24px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 20px;

    h1 {
      font-size: 16px;
    }
    h2 {
      font-size: 12px;
    }
    a{
      font-size: 12px;
    }
  }

  @media screen and (max-height: 800px) and (min-width: 501px){
    margin-bottom: 32px;
    h1 {
      font-size: 20px;
    }
  }
}

.registerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 600px;

  padding: 16px;

  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(60, 64, 67, 0.14),
    0px 1px 2px rgba(60, 64, 67, 0.3);

  h1 {
    font-size: 24px;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 20px;
    h1 {
      font-size: 16px;
    }
  }

  @media screen and (max-height: 800px) and (min-width: 501px){
    h1 {
      font-size: 20px;
    }
  }
}

.link{
  margin-top: 30px;
  color: $primary-button-color;
  text-decoration: underline;
}