.tutorIncompleteProfile {
  width: 70%;
  margin: 16px auto;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}