@import "../../styles/colors.scss";

.classCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-width: 337px;

  padding: 16px;

  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(60, 64, 67, 0.14),
    0px 1px 2px rgba(60, 64, 67, 0.3);
  z-index: 10;
  background-color: white;

  @media screen and (max-width: 500px) {
    max-width: 450px;
    margin-bottom: 12px;
  }

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 16px;

    .description {
        font-size: 22px;
        justify-self: start;

        @media screen and (max-width: 500px) {
          font-size: 8px;
        }
    }
  }

  .title{
    display: flex;
    align-items: center;
    flex-direction: row;

    .titleText {
        grid-area: header;
        margin-left: 12px;
        font-size: 24px;

        @media screen and (max-width: 500px) {
          font-size: 12px;
        }
    }
  }

  .footerActions {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 8px;

    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
  }
}
