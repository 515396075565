
$default-page-padding: 16px;

@mixin page {
  position: relative;

  width: 100%;
  min-height: 100vh;
  padding: $default-page-padding;

  @media screen and (max-width: 500px) {
    // min-height: 640px;
    min-height: 90vh;
    margin-bottom: 58px;
  }
}

@mixin card {
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(60, 64, 67, 0.14),
    0px 1px 2px rgba(60, 64, 67, 0.3);
  z-index: 10;
  background-color: white;

  max-width: 360px;

  @media screen and (max-width: 500px) {
    max-width: 450px;
  }
}