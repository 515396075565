@import "../../styles/layout.scss";

.profile {
  @include page;

  :global(.ant-form) {
    max-width: 700px;
  }

  :global(.ant-tabs) {
    max-width: 100%;
  }

  .avatarSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100px;
    margin: 0 auto 16px;

    :global(.ant-avatar) {
      margin-bottom: 8px;
    }
  }
}
