@import "../../styles/layout.scss";

.classDetail {
  @include page;

  position: relative;
  padding: 16px;
  
  .content {
    display: flex;
    flex-direction: column;

    .actions {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 8px;
      align-items: center;
      justify-content: center;

    }

    ul {
      width: 100%;
    }
  }

  .scheduleClass {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100vh;

    p {
      text-align: center;
      line-height: 24px;
    }

    :global(.ant-form) {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 16px;
    }
  }

  .goBackBtn {
    position: absolute;
    z-index: 10;
  }
}
