@import '../../styles/colors.scss';

.header{
  display: flex;
  z-index: 10;
}

.customHeaderItem{
  margin-right: 30px;

  @media screen and (max-width: 500px) {
    margin-right: 10px;
  }
}

.customHeaderItem:hover{
  cursor: pointer;
}

.customHeaderText {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0em;
  margin-bottom: 3px;
  color: var(--secondary-color);

  @media screen and (max-width: 500px) {
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 3px;
  }
}
.customHeaderUnderline {
  width: 100%;
  height: 1px;
  background-color: var(--secondary-color);
  visibility: visible;
}