@import '../../styles/colors.scss';

.ant-btn:not(.ant-btn-circle):not(.ant-input-search-button) {
  border-radius: 20px;
  width: fit-content;
  min-width: 100px;
}

.ant-btn-primary {
  text-transform: uppercase;
}