@import '../../styles/colors.scss';

.subjectCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  max-width: 320px;
  padding: 16px;

  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(60, 64, 67, 0.14), 0px 1px 2px rgba(60, 64, 67, 0.3);

  background-color: white;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    max-width: 450px;
  }

  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    width: 100%;

    span {
      font-size: 12px;
    }
  }

  .subjectHeader {
    display: flex;
    width: 100%;
    align-items: center;

    .subjectTitle {
      display: flex;
      flex-direction: column;
      flex: 8;

      h2 {
        margin-bottom: 4px;
      }
    }
    
  }

  .description {
    font-weight: 600;
  }
}