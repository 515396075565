.description {
  background: #fff;
  padding: 24px;
  width: 450px;
  max-width: 100%;
  margin: 0 auto;

  border: 1px solid rgb(222, 222, 222);
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.3125em;
    color: rgb(46, 46, 46); 
  }

  p {
    font-size: 0.875em;
    font-weight: 400;
    line-height: 1.3125em;

    color: rgb(117, 117, 117);

    margin-top: 4px;
  }

  img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }

  span {
    font-size: 0.875em;
    font-weight: 500;
    line-height: 1.3125em;
    color: rgb(46, 46, 46); 
  }

  .info {
    margin-left: 12px;
  }

  .teacher {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  

}

@media (max-width: 321px) {
  .description {
    p {
      font-size: 0.75em;
    }

    h2{
      font-size: 0.75em;
    }

    span {
      font-size: 0.75em;
    }
  }
}