.timeForm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: -5px;

  .timeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 9999px;
    width: 65px;
    padding: 8px 0 8px 0;
    margin: 5px;
    cursor: pointer;
  }

  @media screen and (max-width: 658px) {
    font-size: 90%;
    margin: calc(-1.5vw + (0.015 * 232px));

    .timeButton {
      margin: calc(1.5vw - (0.015 * 232px));
      width: calc(22vw - (0.22 * 232px));
    }
  }

  @media screen and (max-width: 500px) {
    margin: calc(-1.5vw + (0.015 * 32px));

    .timeButton {
      margin: calc(1.5vw - (0.015 * 32px));
      width: calc(22vw - (0.22 * 32px));
    }
  }

  @media screen and (max-width: 300px) {
    font-size: 80%;
  }
}
