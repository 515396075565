.dayForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: -3px;

  .month {
    display: flex;
    justify-content: center;
    width: calc(100% - 32px);
    margin-bottom: 10px;
    font-size: 125%;
    color: dimgrey;
    font-weight: bold;
  }

  .months {
    display: flex;
    justify-content: center;
    width: calc(100% - 32px);
    margin-bottom: 10px;
    font-size: 110%;
    color: dimgrey;
    font-weight: bold;
  }

  .passButton {
    font-size: 32px;
  }

  .monthPass {
    margin-left: auto;
  }

  .daysCarousel {
    display: flex;
    align-items: center;
    touch-action: pan-x;
  }

  .dayButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 9999px;
    width: 51.15px;
    height: 51.15px;
    margin: 2px;
    cursor: pointer;

    .monthDay {
      font-weight: bold;
    }

    .dayOfWeekMobile {
      @media screen and (min-width: 659px) {
        display: none;
      }
    }
  }

  @media screen and (max-width: 658px) {
    margin: calc(-0.3vw + (0.003 * 232px));

    .month {
      font-size: 110%;
    }

    .months {
      font-size: 90%;
    }

    .passButton {
      font-size: calc(7.11vw - (0.0711 * 232px));
    }

    .dayButton {
      width: calc(11.655vw - (0.11655 * 232px));
      height: calc(11.655vw - (0.11655 * 232px));
      font-size: 85%;
      margin: calc(0.3vw - (0.003 * 232px));

      .dayOfWeekDesktop {
        display: none;
      }
    }
  }

  @media screen and (max-width: 500px) {
    margin: calc(-0.925vw + (0.00925 * 32px));

    .passButton {
      font-size: calc(7.11vw - (0.0711 * 32px));
    }

    .dayButton {
      width: calc(10.5vw - (0.105 * 32px));
      height: calc(10.5vw - (0.105 * 32px));
      font-size: 85%;
      margin: calc(0.925vw - (0.00925 * 32px));

      .dayOfWeekDesktop {
        display: none;
      }
    }
  }

  @media screen and (max-width: 300px) {
    margin: calc(-0.85vw + (0.0085 * 32px));

    .month {
      font-size: 100%;
    }

    .months {
      font-size: 80%;
    }

    .passButton {
      font-size: calc(6.2vw - (0.062 * 32px));
    }

    .dayButton {
      width: calc(11vw - (0.11 * 32px));
      height: calc(11vw - (0.11 * 32px));
      margin: calc(0.85vw - (0.0085 * 32px));
      font-size: 70%;

      .dayOfWeekDesktop {
        display: none;
      }
    }
  }
}
