@import '../../styles/layout.scss';

.chatCard {
  @include card;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2px 8px;

  &.selected {
    background-color: #dee2e6;
  }

}