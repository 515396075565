@import '../../styles/layout.scss';

.confirmEmailPage {
    @include page;
  
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    min-height: 100vh;
  
    @media screen and (max-width: 500px) {
      margin-bottom: 0;
    }
}

.headerNavigation {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;

  @media screen and (max-width: 500px) {
    margin-right: 16px;
    margin-top: 24px;
  }
}

.footer {
  width: 100%;
  margin-top: 16px;
  position: absolute;
  bottom: 0;
  content: url('../../assets/desktopFooter.png');

  @media screen and (max-width: 500px){
    margin-top: 0;
    content: url('../../assets/footer.png');
  }
}