.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.headerSimple {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 8px;
}

.popupMenu {
  background-color: white;
  box-shadow: 0px 2px 6px rgba(60, 64, 67, 0.14), 0px 1px 2px rgba(60, 64, 67, 0.3);
  padding: 16px;
  border-radius: 5px;
  z-index: 1000;
}

.popupItem {
  padding-right: 32px;
}

.popupItem:hover {
  cursor: pointer;
}