.scheduleForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  max-width: 100vw;
  width: 450px;

  .sectionTitle {
    padding-top: 10px;
    margin-bottom: 25px;
    font-weight: bold;
    font-size: 125%;

    &:nth-child(n + 2) {
      margin-top: 40px;
    }
  }

  @media screen and (max-width: 658px) {
    width: calc(100vw - 200px);

    .sectionTitle {
      font-size: 110%;
    }
  }

  @media screen and (max-width: 500px) {
    width: 100vw;
  }

  @media screen and (max-width: 300px) {
    .sectionTitle {
      font-size: 100%;
    }
  }
}
