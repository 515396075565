.teacherProfile {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    :global(.ant-avatar) {
      margin: 0 8px;
    }
  }

  .content {
    margin: 16px 0;
    p {
      white-space: pre-wrap;
    }
  }
}