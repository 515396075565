@import '../../styles/colors.scss';

.menu {
  padding: 32px 0;

  min-height: 100vh;

  @media screen and (max-width: 500px) {
    display: none;
  }

  img { 
    max-width: 160px;
    max-height: 80px;
  }

  :global(.ant-layout-sider-children) {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    :global(.ant-menu) {
      margin-top: 24px;
      width: 100%;
      border-right: 1px solid;

      :global(.ant-menu-item) {
        color: var(--highlight-color);

        width: 95%;
        border-top-right-radius: 50vh;
        border-bottom-right-radius: 50vh;

        a {
          color: var(--highlight-color);
        }

        &:global(.ant-menu-item-active) {
          color: var(--highlight-color);

          a {
            color: var(--highlight-color);
          }
        }

        &:global(.ant-menu-item-selected) {
          
          a {
            color: inherit;
          }

        }
      }
    }
  }

}

.mobileMenu {

  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height: 50px;
  padding: 4px;
  background-color: #fff;

  position: fixed;
  bottom: 0;
  z-index: 100;

  border-top: 1px solid $dark-light;


  .mobileMenuItem {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    height: 100%;

    border-right: 1px solid $dark-light;

    &:last-child {
      border-right: none;
    }
    
    .title {
      font-size: 11px;
    }

    &.active::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: var(--secondary-color);
      bottom: -8px;
    }
  }


  @media screen and (min-width: 500px) {
    display: none;
  }
}