.studentIncompleteProfile {
  width: 70%;
  margin: 0 auto;
  margin-top: 16px;
}

.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}