@import '../../styles/layout.scss';

.loginPage {
  @include page;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;

  @media screen and (max-width: 500px) {
    padding-top: 18% !important;
    margin-bottom: 0;
  }
}

.headerNavigation {
  display: flex; 
  position: absolute; 
  top: 0; 
  right: 0; 
  margin-top: 10px;

  @media screen and (max-width: 500px) {
    margin-right: 16px;
    margin-top: 24px;
  }
}
