.newMessageForm {
  display: flex;
  align-items: flex-start;

  width: 100%;
  

  @media screen and (max-width: 500px) {
    position: fixed;
    bottom: 0;
    left: 8px;
    width: calc(100% - 16px);
    z-index: 100;
    background-color: #f1f3f5;
    padding: 8px 0;
  }

  :global(.ant-input) {
    margin-right: 8px;
  }
}