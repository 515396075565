.registerForm {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;

  img {
    width: 100px;
  }

  h1 {
    font-size: 24px;
  }

  @media screen and (max-width: 500px) {
    img {
      width: 60px;
    }
    h1 {
      font-size: 16px;
    }
  }
}