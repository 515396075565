@import "../../styles/colors.scss";

.classCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-width: 337px;

  padding: 16px;
  padding-right: 32px;

  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(60, 64, 67, 0.14),
    0px 1px 2px rgba(60, 64, 67, 0.3);
  z-index: 10;
  background-color: white;

  @media screen and (max-width: 500px) {
    width: 40%;
    margin-top: 24px;
  }

  .info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 12px;

    .title {
        font-size: 24px;

        @media screen and (max-width: 500px) {
          font-size: 12px;
        }
    }

    .content {
        width: 100%;
        font-size: 32px;
        font-weight: 500;
        display: flex;
        justify-content: right;

        @media screen and (max-width: 500px) {
          font-size: 18px;
        }
    }
  }

}
