@import "../../styles/colors.scss";

.classCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  max-width: 360px;

  padding: 16px;

  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(60, 64, 67, 0.14),
    0px 1px 2px rgba(60, 64, 67, 0.3);
  z-index: 10;
  background-color: white;

  @media screen and (max-width: 500px) {
    max-width: 450px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 16px;

    .info {
      display: grid;
      column-gap: 8px;
      row-gap: 8px;
      max-width: 240px;
      grid-template-areas:
        "header header"
        "date time"
        "notScheduled notScheduled"
        "status status"
        "justification justification";

      strong {
        grid-area: header;
        font-size: 14px;
        font-weight: 500;
      }

      span {
        font-size: 11px;
        justify-self: start;
      }

      .date {
        grid-area: date;
      }

      .time {
        grid-area: time;
      }

      .status {
        grid-area: status;
      }

      .notScheduled {
        color: $primary-color;
        grid-area: notScheduled;
      }

      .limitToSchedule {
        grid-area: status;
      }

      .justification {
        grid-area: justification;
        font-size: 11px;
      }
    }
  }

  .footerActions {
    width: 100%;

    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
  }
}
