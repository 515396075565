.classDetailHeader {
  display: flex;
  justify-content: space-between;

  width: calc(100% - 30px);
  position: relative;
  margin-bottom: 16px;
  margin-left: 30px;

  .content {
    display: grid;
    column-gap: 8px;
    grid-template-areas: 
        "header header"
        "date time"
        "value ."
        ". ."
        ". .";

    align-items: center;
    justify-content: center;
  
    strong {
      grid-area: header;
      font-weight: 500;
    } 
    
    span {
      font-size: 11px;
    }
  
    span:nth-child(2) {
      grid-area: date;
    }
  
    span:nth-child(3) {
      grid-area: time;
    }

    span:nth-child(4) {
      grid-area: value;
    }

  }

  :global(.ant-divider-horizontal) {
    position: absolute;
    margin: 0;
    width: 80%;
    min-width: 80%;
    bottom: 0;
  }

  :global(.ant-avatar-lg) {
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
  }


}