@import '../../styles/colors.scss';

.event_Solicitada {
  background-color: #ffd591;
}

.event_Agendada {
  background-color: #b7eb8f;
}

.event_Ativa {
  background-color: #e6f7ff;
}

.event_Concluída {
  background-color:#e6f7ff;
}

.event_Cancelada, .event_Recusada {
  background-color: #fff1f0;
}

.fc .fc-toolbar.fc-header-toolbar {
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.fc .fc-button-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.fc .fc-button-active {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.fc .fc-button:focus {
  box-shadow: none !important;
}