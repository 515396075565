@import "../../styles/layout.scss";

.wallet {
  @include page;

  :global(.ant-form) {
    max-width: 700px;
  }

  :global(.ant-tabs) {
    max-width: 100%;
  }
}
