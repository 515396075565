@import '../../styles/layout.scss';

.forgotPasswordPage {
  @include page;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;

  @media screen and (max-width: 500px) {
    margin-bottom: 0;
  }
}

.headerNavigation {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  margin-top: 10px;
  margin-left: 100px;
  z-index: 10;

  @media screen and (max-width: 500px) {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 24px;
  }
}