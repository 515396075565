@import 'react-big-calendar/lib/sass/styles';

:root {
	--secondary-color: #ED3833;
  --btn-text-color: #FFFFFF;
	--primary-color: #1C4E4E;
	--highlight-color: #DFDAE4;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  vertical-align: baseline;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

strong {
	font-weight: 700;
}

textarea {
	resize: none;
}

p.preFormatedParagraph {
	white-space: pre-wrap;
}


// Resets on Antd styles

.ant-form .ant-form-item .ant-form-item-label, .ant-form .ant-form-item .ant-form-item-control {
	padding: 0;
}

.ant-form-item-label > label {
	font-size: 10px !important;
	line-height: 12px !important;
	color: #5F5F5F !important;
}

.ant-radio-group {
	width: 100%;
	
	display: flex !important;

	.ant-radio-button-wrapper {
		flex: 1;
		text-align: center;
	}
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
	border-right-color: var(--secondary-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
	background-color: var(--secondary-color);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	color: var(--secondary-color);
	background: rgba(237, 56, 51, 0.1);
	border: 1px solid var(--secondary-color);
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active,
.ant-tabs-tab-btn {
	color: var(--secondary-color);
	font-weight: 400;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--secondary-color);
	font-weight: 500;
}

.ant-table-wrapper {
	width: 100%;
}

.ant-picker {
	width: 100%;
}

.ant-divider {
	&::before {
		display: none;
	}

	.ant-divider-inner-text {
		padding-left: 0;
	}
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner  {
	border-color: var(--secondary-color);
}

.ant-radio-checked .ant-radio-inner {
	border-color: var(--secondary-color);
}

.ant-radio-checked::after {
	border: 1px solid var(--secondary-color);
}

.ant-radio-inner::after {
	background-color: var(--secondary-color);
}

.ant-tabs-nav-wrap {
	@media screen and (min-width: 600px) {
		overflow: hidden;
		white-space: nowrap;
		display: flex;
		justify-content: flex-end;
	}
}

.ant-tabs-tabpane {
	padding: 8px 16px;
}

.ant-tabs-nav .ant-tabs-tab:hover {
	color: var(--secondary-color);
	font-weight: 400;
}

.ant-btn-primary {
  color: var(--btn-text-color);
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: var(--btn-text-color);
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.ant-btn:not(.ant-btn-primary):hover, .ant-btn:not(.ant-btn-primary):focus {
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.ant-input-affix-wrapper:hover {
  border-color: var(--secondary-color);
}

.ant-input:focus, .ant-input-focused, .ant-input:hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input-search-enter-button input:hover, .ant-input-search-enter-button input:focus,
.ant-input-affix-wrapper:focus
{
	border-color: var(--secondary-color);
  box-shadow: none;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.and-select-customize-input) .ant-select-selector, .ant-input-affix-wrapper-focused {
	border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px rgba(122, 122, 122, 0.11);
}

.ant-input:focus, .ant-input-focused {
	box-shadow: 0 0 0 2px rgba(122, 122, 122, 0.11);
}

.ant-table-row {
	cursor: pointer;
}

input[type="time"]::-webkit-calendar-picker-indicator {
	display: none;
}

.ant-pagination-item-active {
  border-color: var(--secondary-color);

  & a {
    color: var(--secondary-color);
  }
}

.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.ant-pagination-item:focus, .ant-pagination-item:hover {
  border-color: var(--secondary-color);

  & a {
    color: var(--secondary-color);
  }
}

a.fc-col-header-cell-cushion, a.fc-daygrid-day-number {
  color: var(--secondary-color);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--secondary-color);
}

.ant-checkbox-checked::after {
  border-color: var(--secondary-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--secondary-color);
}