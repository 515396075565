@import '../../styles/layout.scss';

.loginPage {
  @include page;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;

  @media screen and (max-width: 500px) {
    margin-bottom: 0;
  }

}
