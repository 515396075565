@import '../../styles/layout.scss';


.registerPage {
  @include page;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100vh;
  margin: 0 auto;

  padding: 0 !important;
  padding-top: 8% !important;

  @media screen and (max-width: 500px) {
    padding-top: 18% !important;
  }

  :global(.ant-form) {
    width: 80%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :global(.ant-form-item) {
      margin-bottom: 8px;
    }

    :global(.ant-form-item) {
      width: 100%;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    margin-top: 10px;
    margin-left: 100px;

    @media screen and (max-width: 500px) {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 24px;
    }
  }

  .footer {
    width: 100%;
    align-self: flex-end;
    margin-top: 16px;
    content: url('../../assets/desktopFooter.png');

    @media screen and (max-width: 500px){
      margin-top: 16px;
      content: url('../../assets/footer.png');
    }
  }
}
