@import '../../styles/layout.scss';

.messagesPage {
  @include page;

  width: 100%;

  .body {
    display: grid;
    grid-template-columns: 30% calc(70% - 16px);
    column-gap: 16px;

    .list {
      height: calc(100vh - 89px);
      overflow-y: auto;
    }

    .chat {
      background-color: #f1f3f5;
      padding: 16px;

      border: 1px solid #ced4da;
      border-radius: 8px;

      height: calc(100vh - 89px);
    }

    @media screen and (max-width: 500px) {
      grid-template-columns: 100%;
    
      .chat {
        position: fixed;
        left: 100%;
        top: 0;
        z-index: 100;
        width: 100%;
        height: 100vh;
        transition: left linear .3s;

        &.visible {
          left: 0;  
        }
      }

    }
  }
}