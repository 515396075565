@import '../../styles/layout.scss';

.forgotPasswordPage {
  @include page;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 !important;
  padding-top: 15% !important;

  min-height: 100vh;

  @media screen and (max-width: 500px) {
    padding-top: 25% !important;
    margin-bottom: 0;
  }
}

.headerNavigation {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  margin-top: 10px;
  margin-left: 100px;
  z-index: 10;

  @media screen and (max-width: 500px) {
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 24px;
  }
}

.footer {
  width: 100%;
  align-self: flex-end;
  margin-top: 16px;
  content: url('../../assets/desktopFooter.png');

  @media screen and (max-width: 500px){
    margin-top: 0;
    content: url('../../assets/footer.png');
  }
}