@import '../../styles/layout.scss';

.classListPage {
  @include page;

  :global(.ant-tabs-content) {
    padding: 4px;
  }
}


.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(288px, 360px));
  gap: 8px;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(auto-fill, minmax(288px, 450px));
  }
}