.adminBalance {
  .balance {
    width: 300px;

    li {
      display: flex;
      justify-content: space-between;

      span:first-child {
        font-weight: 600;
      }
    }
  }
  .balanceText {
    font-weight: bold;
    font-size: 2rem;
  }
}