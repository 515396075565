@import '../../styles/layout.scss';

.chatPage {
  position: relative;
  height: 100%;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
  
    position: absolute;
    top: -16px;
    left: -16px;
    background-color: white;
    padding: 8px;

    width: calc(100% + 32px);
    z-index: 99;

    border-bottom: 1px solid #ced4da;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    h3 {
      margin-left: 16px;
    }

    :global(.anticon) {
      display: none;
    }

    @media screen and (max-width: 500px) { 

      position: fixed;
      top: 0;
      left: 0;

      :global(.anticon) {
        display: block;
        margin-right: 16px;
      }
    }
  }

  .messages {
    position: relative;
    max-height: calc(100% - 40px);
    overflow-y: auto;
    padding-top: 40px;
  }

  .form {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .message {
    @include card;
    padding: 8px;
    margin: 0 auto 8px 8px;
    background-color: #f8f9fa;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 12px;
      margin-top: 8px;
    }

    &.fromMe {
      align-items: flex-end;
      background-color: #e6fcf5;
      margin: 0 8px 8px auto;
    }

    @media screen and (max-width: 500px) { 
      max-width: 80%;
    }
  }
}