.title {
  font-size: 32px;
}

.subtitle{
  font-size: 16px;
}

.sectionTitle{
  font-size: 20px;
}

.sectionDivider{
  padding-top: 24px;
}

.sectionDescription {
  padding-bottom: 24px;
}

.saveButton {
  float: right;
  margin-top: 25px;
  margin-right: 12px;
}

.avatarSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100px;
  margin: 0 auto 16px;
  margin-bottom: 25px;

  :global(.ant-avatar) {
    margin-bottom: 8px;
  }
}
